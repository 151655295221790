import React, {useState} from 'react'
import moment from 'moment'

import formatMoney from '../../assets/functions/formatMoney.js'

export default function Purchase({purchase}) {
  const [expanded, setExpanded] = useState(false)

  const requiredResources = purchase.redemptions.length
  if (!requiredResources) return <></>

  const offer = purchase.redemptions[0].offer
  const timesRedeemed = purchase.redemptions.length

  const mediaUpload = purchase.media_upload
  const mediaUploadImages = mediaUpload?.media_upload_images
  const mediaUploadStatus = mediaUpload?.status

  return (
    <div>
      <div
        onClick={() => setExpanded(!expanded)}
        className='flex p-2 cursor-pointer'>
        <div className='w-14 flex flex-col justify-center'>
          <div>{moment(purchase.purchase_date).format('MMM')}</div>
          <div>{moment(purchase.purchase_date).format('D')}</div>
        </div>

        <div className='flex-1 flex flex-col justify-center text-left'>
          <div>{`${offer.name} ${
            timesRedeemed > 1 ? 'x' + timesRedeemed : ''
          }`}</div>
          <div className='text-xs font-secondary'>
            Status: {mediaUploadStatus || 'unknown'}
          </div>
        </div>

        <div className='w-14 flex flex-col justify-center'>
          {formatMoney(offer.discount_cents * timesRedeemed)}
        </div>
      </div>

      {expanded && mediaUploadImages ? (
        <div className='flex flex-wrap gap-2 p-2 justify-center'>
          {mediaUploadImages.map((mui, index) => {
            return (
              <img
                key={`mediaUploadImage_${mediaUpload.hashed_id}_${index}`}
                src={mui.image_url}
                className='w-64'
                alt='Receipt Image'
              />
            )
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
