import React, {useState} from 'react'

import formatMoney from '../../assets/functions/formatMoney'

import Purchase from './purchase'

import Arrow from '../shared/icons/arrow'

export default function ConsumerBalance({consumerBalance, initiallyExpanded}) {
  const [expanded, setExpanded] = useState(initiallyExpanded)

  return (
    <>
      <div
        onClick={() => setExpanded(!expanded)}
        className='flex p-2 cursor-pointer'>
        <div className='w-14 flex flex-col justify-center items-center'>
          {expanded ? <Arrow direction='up' type='double' /> : <Arrow direction='down' />}
        </div>

        <div className='flex-1 flex flex-col justify-center text-left'>
          <div>Status: {consumerBalance.status}</div>
        </div>

        <div className='w-14 flex flex-col justify-center'>
          {formatMoney(consumerBalance.balance_cents)}
        </div>
      </div>

      {expanded ? (
        consumerBalance.purchases.map((purchase) => {
          return (
            <Purchase
              key={`consumerBalance_${consumerBalance.hashed_id}_purchase_${purchase.hashed_id}`}
              purchase={purchase}
            />
          )
        })
      ) : (
        <></>
      )}
    </>
  )
}
